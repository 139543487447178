import { Container, Grid, Typography,TextField, Button } from "@mui/material";
import React from "react";
import './contactus.css'
import { Helmet } from "react-helmet";
export default function ContactUS(){
    const [data,setData]=React.useState({
        name:'',
        email:'',
        phone:'',
        message:'',
        service:'',
      
      
    
    })
    const [processing,setProcessing]=React.useState(false)
const [submitted,setSubmitted]=React.useState(false)


    const handleSubmit=()=>{

        if(data.name&&data.email&&data.phone&&data.service){
            setProcessing(true)
      
        
        
        
        
        
        
        
            // fetch('https://halblieter.vercel.app/contact'
            fetch('https://halblieter-u5kg.vercel.app/contact'
            ,
            {
            
              method:'POST',
              headers: { 'Content-Type': 'application/json' },

              body:JSON.stringify(data)
              })
            
              .then(res=>res.json())
              .then((resp)=>MailRes(resp.detail))
            
            
              .catch(error=>console.log(error))
          
        }
        else{
            alert('Please fill all the mandatory fields')
        }
     
        }

        
    const MailRes=(val)=>{
        setProcessing(false)
  setSubmitted(true)
  setData({
  ...data,name:'',email:'',phone:'',message:'',service:''
  })
  setSubmitted(()=>setTimeout(() => setSubmitted(false), 10000))   
  }

    return(
        <>
         <Helmet>
        {/* <!-- HTML Meta Tags --> */}
    
    <title>Contact Us</title>
       

        <meta
          name="description"
          content={'Lets talk about your business'}
        />

    <meta name="keywords" content={'Great Planners and Organizers,Incredible Team Working Abilities,Dedicated Expert Teams & Crew'} />

        {/* <!-- Google / Search Engine Tags --> */}
       
   
        <meta itemprop="name" content={'Contact Us'}
         />
        
 
    <meta
    itemprop="description"
    content={'Lets talk about your business'}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content="https://halbleiterengineering.com/contactus" />
  
        <meta property="og:type" content="website" />
       
        <meta property="og:title" content={'Contact Us'} />
  
   
    <meta
          property="og:description"
          content={'Lets talk about your business'}
        />
        
      
      

      
       
      </Helmet>
        <div style={{height:70,background:'var(--blueColor)'}}>

        </div>
        <div className="contact_bg">

        </div>
        <Container>
            <Grid container>
                <Grid item sm={12} xs={12}>
                    <Typography className="contact_head">
                    Get in Touch
                    </Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                    <Typography className="contact_subhead">
                    Let's Talk About  Your Business
                    </Typography>
                </Grid>
                <Grid container>
                    <Grid item style={{width:'90%'}} sm={6} xs={6}>
                    <TextField  style={{width:'90%'}}  id="filled-basic" name="name"  value={data.name}   onChange={(e)=>setData({...data,
                      name:e.target.value
                  })} label="Name*" variant="filled" />
                    </Grid>
                    <Grid item sm={6} style={{width:'90%'}}  xs={6}>
                    <TextField id="filled-basic"   onChange={(e)=>setData({...data,
                        email:e.target.value
                    })}  name="email"
                      value={data.email} style={{width:'90%'}} label="Email*" variant="filled" />
                        </Grid>
                        <Grid item style={{width:'90%',paddingTop:20}} sm={6} xs={6}>
                    <TextField  onChange={(e)=>setData({...data,
                        service:e.target.value
                    })}  name="service"
                      value={data.service} style={{width:'90%'}} id="filled-basic" label="Service*" variant="filled" />
                    </Grid>
                    <Grid item sm={6} style={{width:'90%',paddingTop:20}}  xs={6}>
                    <TextField     onChange={(e)=>setData({...data,
                        phone:e.target.value
                    })}  name="phonenumber"
                      value={data.phone} id="filled-basic" style={{width:'90%'}} label="Phonenumber*" variant="filled" />
                        </Grid>

                       

                        <Grid item sm={12} style={{width:'90%',paddingTop:20}}  xs={12}>
                    <TextField       name='message'
                        onChange={(e)=>setData({...data,
                            message:e.target.value
                        })}       value={data.message}
                       id="filled-basic" style={{width:'95%'}} label="Message" multiline rows={8} variant="filled" />
                        </Grid> 
                        <Grid item sm={12} style={{width:'90%',paddingTop:20}}  xs={12}>
                    <Button onClick={()=>handleSubmit()} variant="contained" className="btn_contact" > {
                    submitted&&!processing?'Submitted':processing?'Submitting':'submit'
                    }</Button>
                        </Grid> 

                </Grid>


            </Grid>

            <Grid container style={{marginTop:100,marginBottom:30,textAlign:'center',width:'95%'}}>
                <Grid container sm={4} xs={12}>
                    <Grid item sm={12} xs={12}>
                    <i class="fas fa-mobile iconC"></i>
                    </Grid>                  <Grid item sm={12} xs={12}>
                 <Typography className="iconC_head">
                    Call us
                 </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                 <Typography className="iconC_text">
                 +91-7428283299
                 </Typography>
                    </Grid>
                </Grid>
                <Grid container sm={4} xs={12}>
                    <Grid item sm={12} xs={12}>
                    <i class="fas fa-envelope iconC"></i>
                    </Grid>                  <Grid item sm={12} xs={12}>
                 <Typography className="iconC_head">
                    Email us
                 </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                 <Typography className="iconC_text">
                 info@halbleiterengineering.com
                 </Typography>
                    </Grid>
                </Grid>
                <Grid container sm={4} xs={12}>
                    <Grid item sm={12} xs={12}>
                    <i class="fas fa-map-marker iconC"></i>
                    </Grid>                  <Grid item sm={12} xs={12}>
                 <Typography className="iconC_head">
                    Visit us
                 </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                 <Typography className="iconC_text">
                 PLOT NO. 3  ACHHEJA GREATER NOIDA, U.P-203207                 </Typography>
                    </Grid>
                </Grid>
            </Grid>

        </Container>
        <Container style={{maxWidth:'100%'}}>
        <div className="ContactUs_Map">
            <iframe 
            //   src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7015.8682294569935!2d77.02272007487564!3d28.45140242995817!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d183a70002b83%3A0x2826fe7d22f50571!2sShivaji%20Nagar%2C%20Sector%2011%2C%20Gurugram%2C%20Haryana%20122022!5e0!3m2!1sen!2sin!4v1608698517297!5m2!1sen!2sin"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5517.930833390445!2d77.36037272887249!3d28.638684038610215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb84a3163ecf%3A0xed9cced9b4910562!2sAssotech%20Cabana!5e0!3m2!1sen!2sin!4v1691837189969!5m2!1sen!2sin"              width="100%"
              height="390"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              // loading="lazy"
              tabIndex="0"
            />
        </div>
        </Container>
        </>

    )
}