import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore' ;

import Accordion from '@mui/material/Accordion';
import{ AccordionSummary,AccordionDetails} from '@mui/material';
import Typography from '@mui/material/Typography';
import ArrowForwardIcon from '@mui/icons-material/ArrowBack';
import './D6_Sl_Drawer.css'
import { Allen, SMC } from '../../homepage/products/products_data';
export default function TemporaryDrawer(props) {

  const [open,setOpen]=React.useState(true)


  

  const Open=()=>{
    setOpen(!open)
  }
const Toggle=()=>{
  props.Toggle()
}

  const list = (anchor) => (
    <Box
      className='box_W'
      role="presentation"
      onClick={Open}
      onKeyDown={Open} 
      
    >
      <List>
        <ListItem onClick={()=>{Toggle()}}>
        <ListItemIcon style={{cursor:'pointer',paddingBottom:'2px',color:'white'}}>
      <ArrowForwardIcon  />
      </ListItemIcon>
      </ListItem>


    


        {(['HOME','ABOUT US']).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='HOME'?'/':text=='ABOUT US'?'/about':text=='Contact us'?'/contactus':text=='Company Compliance'?'/companycompliance':text=='Our services'?'ourservices':'/'}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}



          <Accordion>
        <AccordionSummary
                style={{margin:'5px 0px'}}

          expandIcon={<ExpandMoreIcon style={{color:'white'}}  />}
          aria-controls="panel1a-content"
          id="panel1a-header" 
        >
          <Typography className='typo'  >DOMAIN EXPERTISE</Typography>
        </AccordionSummary>


        <AccordionDetails>
 <Typography  className='typo4' onClick={()=>window.location.href='/domain-expertise/industial-automation'}>INDUSTRIAL AUTOMATION</Typography>

 <Typography  className='typo4' onClick={()=>window.location.href='/domain-expertise/industry-4.0'}>INDUSTRY 4.0</Typography>

          </AccordionDetails>
         
         
         
       
      </Accordion>

<Accordion>
        <AccordionSummary
                style={{margin:'5px 0px'}}

          expandIcon={<ExpandMoreIcon style={{color:'white'}}  />}
          aria-controls="panel1a-content"
          id="panel1a-header" 
        >
          <Typography className='typo'  >PRODUCTS</Typography>
        </AccordionSummary>

        <AccordionDetails style={{display:'grid'}}>

        <Accordion>
        <AccordionSummary 
        // style={{margin:'15px 0px'}}
          expandIcon={<ExpandMoreIcon style={{color:'white'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
        >
                    <Typography   className='typo3'>Allen Bradley Products</Typography>
                    </AccordionSummary>

          <AccordionDetails>
{Allen.map((val,idx)=>
 <Typography key={idx} className='typo4' onClick={()=>window.location.href=`${val.link}`}>{val.name}</Typography>

)       
}
          </AccordionDetails>
        
          </Accordion>
          <Accordion>
        <AccordionSummary 
        // style={{margin:'15px 0px'}}
          expandIcon={<ExpandMoreIcon style={{color:'white'}} />}
          aria-controls="panel1a-content"
          id="panel1a-header1"
        >
                    <Typography   className='typo3'>SMC Products</Typography>
                    </AccordionSummary>

          <AccordionDetails>
{SMC.map((val,idx)=>
 <Typography key={idx} className='typo4' onClick={()=>window.location.href=`${val.link}`}>{val.name}</Typography>

)       
}
          </AccordionDetails>
        
          </Accordion>
         
         
         
       
        </AccordionDetails>
      </Accordion>
   
      {(['CONTACT US']).map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='CONTACT US'?'/contactus':text=='SIGN IN / SIGN UP'?'/signin':text}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography className='typo' >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
       
     
    
      </List>

    </Box>
  );

  return (
    // <div>
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={'left'}
            open={props.open}
            onClose={Open}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </>
    // </div>
  );
}