import React, { useEffect } from 'react'
import './top_services.css'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import { Paper, Typography } from '@mui/material';
export default function Top_services() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 
  return (
    <>
    <div data-aos="fade-in" className='bg_services'>
    <div class="max-width">
          <div class="crousel-content1">
            <h2 data-aos="fade-up"
     data-aos-duration="3000" style={{textTransform:'uppercase'}}  data-aos-delay="300" >Our Product Range
     </h2>
           
            <p data-aos="fade-up"
     data-aos-duration="3000"   data-aos-delay="330"  class="sub-label">     Experience a comprehensive array of Industry Automation essentials under one roof. From state-of-the-art Pneumatic Products to precision-engineered Variable Frequency Drives (VFDs), from versatile Programmable Logic Controllers (PLCs) to intuitive Human Machine Interfaces (HMIs), and from advanced Actuators to reliable Air Cylinders and Valves – Halbleiter offers a curated selection that empowers your automation endeavors.
     </p>
       
          </div>
        </div>


    </div>
   
    </>
  )
}
