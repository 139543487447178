import {  Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import { Automations } from './automation_data';
import Card_C from '../common/card/card';
import { Helmet } from 'react-helmet';
export default function Automation_D() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 
  
// console.log(product,category)


    return (
        <div style={{paddingBottom:'5vh'}}>
             <Helmet>
        <title>Domain Expertise</title>
       

       <meta
         name="description"
         content={'Our comprehensive cloud-based IoT software platform that drives the digital transformation of infrastructure operations. Smart Cloud helps in optimizing the operations of utilities and machines.'}
       />

   <meta name="keywords" content={'Great Planners and Organizers,Incredible Team Working Abilities,Dedicated Expert Teams & Crew'} />

       {/* <!-- Google / Search Engine Tags --> */}
      
  
       <meta itemprop="name" content={'Contact Us'}
        />
       

   <meta
   itemprop="description"
   content={'Our comprehensive cloud-based IoT software platform that drives the digital transformation of infrastructure operations. Smart Cloud helps in optimizing the operations of utilities and machines.'}
 />
      
       
   

       {/* <!-- Facebook Meta Tags --> */}
     
     
       <meta property="og:url" content="https://halbleiterengineering.com/contactus" />
 
       <meta property="og:type" content="website" />
      
       <meta property="og:title" content={'Contact Us'} />
 
  
   <meta
         property="og:description"
         content={'Our comprehensive cloud-based IoT software platform that drives the digital transformation of infrastructure operations. Smart Cloud helps in optimizing the operations of utilities and machines.'}
       />
       
     
     

     
      
     </Helmet>
     <div>
        
     <div style={{background:'var(--blu3)',height:'400px'}}>
 <Typography className='product_name'>
 {'INDUSTRIAL AUTOMATION'}
 </Typography>
 <Typography style={{padding:'4vh 0px',color:'white',fontSize:18}} className='page'>
 <a href='/'  style={{textDecoration:'none',color:'white'}}>Home</a><span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span><a href='/'  style={{textDecoration:'none',color:'white'}}>Domain Expertise</a><span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span>{'Industrial Automation'}
 </Typography>
     </div>
 
     <Container style={{paddingTop:'6vh',maxWidth:'100%',textAlign:'-webkit-center'}}>
      
         <Grid container style={{maxWidth:'100%'}}>
        
         <Grid item sm={12} xs={12}>
                     <Typography className='product_title'>
                         {'INDUSTRIES'}
                     </Typography>
                 </Grid>
      {Automations.map((val,idx)=>
   
   <Grid key={idx} style={{padding:15}} item sm={3} xs={12}>

   <Card_C idx={idx} w={true} val={val} />
   </Grid>
    )  }
   
   </Grid>

           
 
         
        
      
      
       

       
     </Container>
     </div>
 
        </div>
        
      )
}
