import React from 'react'
import About_top from './about_top'
import { Helmet } from 'react-helmet'

export default function About() {
  return (
    <>
    <Helmet>
        {/* <!-- HTML Meta Tags --> */}
    
    <title>About</title>
       

        <meta
          name="description"
          content={'HALBLEITER ENGINEERING had  full team for mechanical design and contract designers include Mechanical Engineers and Technologists, Tool and Die Makers and Machine Builders supported by a team of Tool and Die makers, Machinists, Millwrights, assemblers and developers to ensure a great design.'}
        />

    <meta name="keywords" content={'Great Planners and Organizers,Incredible Team Working Abilities,Dedicated Expert Teams & Crew'} />

        {/* <!-- Google / Search Engine Tags --> */}
       
   
        <meta itemprop="name" content={'About'}
         />
        
 
    <meta
    itemprop="description"
    content={'HALBLEITER ENGINEERING had  full team for mechanical design and contract designers include Mechanical Engineers and Technologists, Tool and Die Makers and Machine Builders supported by a team of Tool and Die makers, Machinists, Millwrights, assemblers and developers to ensure a great design.'}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content="https://halbleiterengineering.com/about" />
  
        <meta property="og:type" content="website" />
       
        <meta property="og:title" content={'About'} />
  
   
    <meta
          property="og:description"
          content={'HALBLEITER ENGINEERING had  full team for mechanical design and contract designers include Mechanical Engineers and Technologists, Tool and Die Makers and Machine Builders supported by a team of Tool and Die makers, Machinists, Millwrights, assemblers and developers to ensure a great design.'}
        />
        
      
      

      
       
      </Helmet>
      
    <About_top />
    </>
  )

}
