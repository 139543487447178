import React, { useEffect } from 'react'
// import './top_studio.css'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import { Avatar, Container, Grid, Paper, Typography } from '@mui/material';
// import '../../studio/top_studio/top_studio.css'
import './bottom_section.css'

export default function Bottom_sectionC() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 
  return (
    <>
  
    <Container>
        <Grid container>
<Grid item sm={12} xs={12}>
<Typography style={{textAlign:'center'}} data-aos="fade-up"
      className='head_about'>
   WHY HALBLEITER SYSTEMS?
</Typography>


</Grid>
<Grid item sm={12} xs={12}>
<Typography style={{paddingBottom:'1vh'}} data-aos="fade-up"
      className='text_about'>
    Discover the power of industry automation with HALBLEITER Engineering and experience the difference that expertise, innovation, and dedication can make for your business.
<br></br>
        {/* Our approach involves custom blending of technology, product and engineering to provide our customers with their best package of solution options.<br/><br/>
The electrical systems design team of full time, in-house designers include Electrical Technologists are supported by Master Electricians, Electricians, Electrical Engineers and senior level Controls Programmers and Developers.
<br/> <br/> The design department is where Innovation starts, consideration of the following is just the start: <br /><br /> */}

</Typography>
</Grid>
</Grid>
<Grid container>

<Grid data-aos="fade-up"
     data-aos-duration="3000"    data-aos-delay="100" container sm={12} xs={12}>


<Grid className='grid_BH' data-aos="zoom-in"
     data-2os-duration="3000"  container  sm={4} xs={12}>
                  <Paper elevation={0} className='ppr_BH'>
                    <Grid container sm={12} xs={12}>
                    <Grid item style={{alignSelf:'flex-start'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-rocket"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'0px 2px 1vh 2px'}}>
  Leading the Innovation Frontier
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >
                  HALBLEITER Engineering stands at the forefront of industry automation, continually pushing boundaries and exploring new horizons in technology and solutions.


</Typography>

                </Grid>
</Grid>
</Grid>
</Paper>
                </Grid>
              
               
                <Grid className='grid_BH' data-aos="zoom-in"
     data-aos-duration="2000"  container  sm={4} xs={12}>
                  <Paper elevation={0} className='ppr_BH'>
                    <Grid container sm={12} xs={12}>
                <Grid item style={{alignSelf:'flex-start'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-tools"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'0px 2px 1vh 2px'}}>
Unparalleled Expertise
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >
                With a team of seasoned Mechanical Engineers, Technologists, Machine Builders, and more, we bring a wealth of knowledge and hands-on experience to every project.

</Typography>

                </Grid>
</Grid>
</Grid>
</Paper>
                </Grid>




               
              
              
         

                <Grid className='grid_BH' data-aos="zoom-in"
     data-aos-duration="2000"  container  sm={4} xs={12}>
                  <Paper elevation={0} className='ppr_BH'>
                    <Grid container sm={12} xs={12}>
                    <Grid item style={{alignSelf:'flex-start'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-globe"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'0px 2px 1vh 2px'}}>
Comprehensive Collaboration
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >
                Our collaborative approach ensures that not just one skill set, but an entire spectrum of talents, from Machinists to developers, work harmoniously to craft remarkable designs.

</Typography>

                </Grid>
</Grid>
</Grid>
</Paper>
                </Grid>
                <Grid className='grid_BH' data-aos="zoom-in"
     data-aos-duration="2000"  container  sm={4} xs={12}>
                  <Paper elevation={0} className='ppr_BH'>
                    <Grid container sm={12} xs={12}>
                    <Grid item style={{alignSelf:'flex-start'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-link"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'0px 2px 1vh 2px'}}>
Seamless Integration
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >
                We seamlessly blend innovative automation technologies into your existing systems, making sure every element aligns perfectly with your operational requirements.

</Typography>

                </Grid>
</Grid>
</Grid>
</Paper>
                </Grid>
                <Grid className='grid_BH' data-aos="zoom-in"
     data-aos-duration="2000"  container  sm={4} xs={12}>
                  <Paper elevation={0} className='ppr_BH'>
                    <Grid container sm={12} xs={12}>
                    <Grid item style={{alignSelf:'flex-start'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-star"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'0px 2px 1vh 2px'}}>
Precision and Excellence
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >
                Our commitment to perfection is unwavering. Each solution is meticulously designed, crafted, and executed to guarantee exceptional performance.

</Typography>

                </Grid>
</Grid>
</Grid>
</Paper>
                </Grid>
                <Grid className='grid_BH' data-aos="zoom-in"
     data-aos-duration="2000"  container  sm={4} xs={12}>
                  <Paper elevation={0} className='ppr_BH'>
                    <Grid container sm={12} xs={12}>
                    <Grid item style={{alignSelf:'flex-start'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-chart-line"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'0px 2px 1vh 2px'}}>
Empowering Your Future
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >
                HALBLEITER Engineering's solutions aren't just for today; they're designed to empower your business well into the future, adapting and scaling as your needs evolve.

</Typography>

                </Grid>
</Grid>
</Grid>
</Paper>
                </Grid>
                <Grid className='grid_BH' data-aos="zoom-in"
     data-aos-duration="2000"  container  sm={4} xs={12}>
                  <Paper elevation={0} className='ppr_BH'>
                    <Grid container sm={12} xs={12}>
                    <Grid item style={{alignSelf:'flex-start'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-globe-asia"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'0px 2px 1vh 2px'}}>
Global Reach, Personal Touch
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >
                From local to global projects, we deliver tailored automation solutions with a personal touch, ensuring your unique needs are always prioritized.

</Typography>

                </Grid>
</Grid>
           
</Grid>
</Paper>
                </Grid>        








</Grid>
</Grid>
    </Container>
    </>
  )
}
