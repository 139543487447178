export const Allen=[{
    'name':'Variable Frequency Drive (VFD)',
    'link':'/products/Allen_Bradley_Products/Variable_Frequency_Drive',
    'details':"PowerFlex® Compact-class AC Drives deliver a simple and cost-effective solution for standalone machine-level control applications or simple system integration. Designed for ease of use, this general-purpose class of drives provides a compact package to optimize panel space and application versatility.",
    'img':require('../../images/VFD.webp')
    ,'products':[
        {'name':"PowerFlex 523 AC Drives",
    "details":"Our PowerFlex® 523 AC Drives are cost-effective and ideal for standalone machines. These drives provide an innovative, modular design that supports fast and easy installation and configuration. In addition, they offer USB programming, application-specific configuration, and flexible mounting options. PowerFlex 523 AC drives offer a power rating of 0.2...22 kW (0.25...30 Hp) with global voltage classes of 100...600V. They support a wide range of motor control, offer a compact footprint, and operate at temperatures as high as 70 °C (158 °F)."
,'img':require('../../images/powerflex1.jpg')


},
{'name':"PowerFlex® 525 AC Drives",
"details":"Our PowerFlex® 525 AC Drives feature an innovative, modular design to support fast and easy installation and configuration. This next generation of compact drives offers embedded EtherNet/IP™ communications, USB programming, and standard safety features. PowerFlex 525 drives, which offer a power rating of 0.4...22 kW (0.5...30 Hp) with global voltage classes of 100...600V, provide a variety of motor control and flexible mounting options. PowerFlex 525 AC drives operate at temperatures as high as 70 °C (158 °F), and they are ideal for applications such as conveyors, fans, pumps, and mixers."
,'img':require('../../images/powerflex2.jpg')


},
{'name':"PowerFlex 527 AC Drives",
"details":"Our PowerFlex® 527 AC Drives are designed to work exclusively with our Logix Programmable Automation Controllers (PACs). This allows the drive to leverage the capabilities of the controller and use Studio 5000 Logix Designer™ to help simplify machine development and use. This streamlined approach uses motion instructions that are shared by PowerFlex 527 AC drives and Kinetix® servo drives, providing the same user experience for configuration, programming, and control of both types of drives."
,'img':require('../../images/powerflex3.jpg')


},
{'name':"PowerFlex 4M AC Drives",
"details":"Our PowerFlex® 4M AC Drives are the smallest and most cost-effective members of the PowerFlex family of drives. Feed-through wiring and easy programming provide the flexibility to handle a broad range of applications."
,'img':require('../../images/powerflex4.jpg')


},
{'name':"PowerFlex 4 AC Drives",
"details":"Our PowerFlex® 4 AC Drives are designed to meet global OEM and end-user requirements for simplicity, space savings, and cost efficiency. These compact drives provide intuitive features such as an integral keypad with local potentiometer and control keys that are active right out of the box."
,'img':require('../../images/powerflex5.jpg')


},
{'name':"PowerFlex 40 AC Drives",
"details":"Our PowerFlex® 40 AC Drives provide OEMs, machine builders, and end users with performance-enhancing motor control in an easy-to-use compact package. These  drives feature sensorless vector control to meet low-speed torque demands. With flexible enclosure options, and simple programming, they can be installed and configured quickly. We also have packaged drives that provide additional control, power, and enclosure options in standardized designs."
,'img':require('../../images/powerflex6.jpg')


},
{'name':"PowerFlex 40P AC Drives",
"details":"Our PowerFlex® 40P AC Drives provide closed-loop control with an option for Category 3 Safe Torque-off in a compact and cost-effective design. This drive is designed to meet global OEM and end-user demands for flexibility, space savings and ease of use. It is a cost-effective solution for speed or basic position control of applications such as diverters, smart conveyors, packaging machines, and fiber-spinning machines."
,'img':require('../../images/powerflex7.jpg')


},
{'name':"PowerFlex 400 AC Drives",
"details":"Our PowerFlex® 400 AC Drives are optimized for control of commercial and industrial fans and pumps. Built-in features such as purge and damper input provide a cost-effective solution for speed control in a broad range of variable torque fan and pump applications. An available packaged PowerFlex 400 Fan and Pump drive provides additional control, power, and enclosure options in standardized designs for a cost-effective solution for speed control in variable torque fan and pump applications."
,'img':require('../../images/powerflex1.jpg')


},
]
},{
    'name':'Human Machine Inteface (HMI)',
    'link':'/products/Allen_Bradley_Products/Human Machine Inteface',
   'img':require('../../images/HMI.webp')

    ,'details':"Our Graphic Terminals offer rugged electronic interface solutions in a variety of sizes, operator input methods and configurations. These robust devices are fully packaged (hardware, software and communications) and tested for human-machine interface operation. They have earned ratings for high shock, vibration, and temperature. "
,'name2':'Graphic Terminals'
,'products':[
    {'name':"OptixPanel Graphic Terminals",
'img':require('../../images/PanelViewA.webp')


},
 {'name':"PanelView 5000 Graphic Terminals",
'img':require('../../images/PanelViewB.webp')


}, {'name':"PanelView Plus 7",
'img':require('../../images/PanelViewC.webp')


}, {'name':"PanelView Plus 6",
'img':require('../../images/PanelView1.webp')


},
{'name':"PanelView Plus 6 Compact",
'img':require('../../images/PanelView2.webp')


},
{'name':"2711R PanelView 800",
'img':require('../../images/PanelView3.jpg')


},


],
},{
    'name':'Programmable Logic Controller (PLC) ',
    'link':'/products/Allen_Bradley_Products/Programmable_Logic_Controller',
    'img':require('../../images/PLC.webp')
,
    'details':"For the most demanding applications, the smart machines and equipment for manufacturing, the Process opportunities, and the basic and smart control needs: meet all these requirements with our Large, Small, Process and Micro Control Systems. Not forgetting your safety concerns, our safety-certified controllers from Large and Small Control Systems support your SIL 2 and SIL 3 application needs. "

    ,'products':[
        
        {
            "head":'Large Control Systems',
            'name':"ControlLogix 5580 Controllers",
        "details":"Ideal for applications that require high availability, high-performance communications, I/O, and motion control for up to 256 axes"
    ,'img':require('../../images/PLC1.webp')
    
    },{
        "head":'Large Control Systems',
        'name':"Process Controllers",
    "details":" Accelerate your digital transformation with PlantPAx® 5.0 DCS version and unlock more opportunities with our ControlLogix® 5580 and CompactLogix™ 5380 Process controllers"
,'img':require('../../images/PLC2.webp')

},
,{
    "head":'Large Control Systems',
    'name':"ControlLogix 5570 Controllers",
"details":"Available in standard, safety, extreme temperature, and on-machine models, suitable for process, motion, discrete, and high availability applications."
,'img':require('../../images/PLC3.jpg')

},{
    "head":'Large Control Systems',
    'name':"Enhance Cybersecurity & Reliability in Your ControlLogix Control System",
"details":"Our ASEM™ 6300 Industrial Computers are designed to enhance the security and reliability of your ControlLogix® control system. Each model is individually designed to minimize or remove frequent points of failure to create a more secure, reliable, and cost-effective visualization system."
,'img':require('../../images/PLC4.webp')

}],
'products2':[{
    "head":'Small Control Systems',
    'name':"CompactLogix 5480 Controllers",
"details":"CompactLogix™ 5480 controllers are real-time controllers with Windows 10 IoT Enterprise running in parallel to the Logix control engine. "
,'img':require('../../images/PLC5.webp')

},{
    "head":'Small Control Systems',
    'name':"CompactLogix 5380 Controllers",
"details":"Our CompactLogix™ and Compact GuardLogix® 5380 controllers provide higher performance, increased capacity, improved productivity and enhanced security to help meet the growing demands of smart machines and equipment for manufacturing."
,'img':require('../../images/PLC6.webp')

},
{
    "head":'CompactLogix 5370 Controllers',
    'name':"CompactLogix 5370 Controllers",
"details":"CompactLogix™ 5370 Controllers are part of the Integrated Architecture® system. These controllers include integrated safety, and use the same programming software, network protocol, and information capabilities as all Logix controllers."
,'img':require('../../images/PLC8.jpg')

},
{
    "head":'Small Control Systems',
    'name':"SmartGuard 600",
"details":"SmartGuard™ 600 Controllers with Safety feature 16 safety-rated inputs, 8 safety-rated outputs, 4 pulse test sources and an optional EtherNet/IP™ port. Each controller also includes a DeviceNet™ connection to support both standard CIP and CIP safety. "
,'img':require('../../images/PLC9.webp')

},
{
    "head":'Small Control Systems',
    'name':"SLC 500 Controllers",
"details":"Main ImageSLC™ 500 control platform is used for a wide variety of applications. Rockwell Automation has announced that some SLC 500 Bulletin numbers are discontinued and no longer available for sale."
,'img':require('../../images/PLC10.jpg')

},
]
,'products3':[
    {
        "head":'Micro Control Systems',
        'name':"Micro870 Programmable Logic Controller Systems",
    "details":"Our Bulletin 2080 Micro870® programmable logic controllers (PLC) are designed for large standalone machine control applications that require flexible communications and greater I/O capabilities."
    ,'img':require('../../images/PLC11.webp')
        
    },
    {
        "head":'Micro Control Systems',
        'name':"Micro850 Programmable Logic Controller Systems",
    "details":"Our Bulletin 2080 Micro850® programmable logic controllers (PLC) are designed for larger standalone machine control applications that require flexible communications and greater I/O capabilities."
    ,'img':require('../../images/PLC12.jpg')
        
    },
    {
        "head":'Micro Control Systems',
        'name':"Micro820 Programmable Logic Controller Systems",
    "details":"Our Micro820® Programmable Logic Controller Systems include a nano-sized footprint and are designed for small standalone machine control and remote automation applications that require flexible communications and I/O capabilities."
    ,'img':require('../../images/PLC14.jpg')
        
    },
    {
        "head":'Micro Control Systems',
        'name':"Micro810 Programmable Logic Controller Systems",
    "details":"With a nano-sized footprint, our Bulletin 2080 Micro810® programmable logic controllers (PLC) function as smart relays with high-current relay outputs, but with the programming capabilities of micro-PLCs."
    ,'img':require('../../images/PLC15.jpg')
        
    },
    {
        "head":'Micro Control Systems',
        'name':"Micro800 PLC Plug-in Modules and Accessories",
    "details":"With our Bulletin 2080 Micro800™ programmable logic controllers (PLC) plug-in modules and accessories, you can adapt your base unit controller to fit your application needs."
    ,'img':require('../../images/PLC16.webp')
        
    },
    {
        "head":'MicroLogix 1400 Programmable Logic Controller Systems',
        'name':"MicroLogix 1400 Programmable Logic Controller Systems",
    "details":"Our Bulletin 1766 MicroLogix™ 1400 Programmable Logic Controller Systems build upon critical MicroLogix 1100 features: EtherNet/IP™, on-line editing and a built-in LCD panel."
    ,'img':require('../../images/PLC17.webp')
        
    },

]
}]

export const SMC=[
    
    {
    'name':'Air Dryer',
    'link':'/products/SMC_Products/Air_Dryer',
    'img':require('../../images/SMCM1.jpg'),

    'details':"Elevate your compressed air system's performance with our advanced SMC Air Dryers. Designed to efficiently remove moisture and contaminants from your air supply, these dryers ensure optimal air quality for your industrial processes. With a focus on reliability and energy efficiency, SMC Air Dryers help prevent corrosion, extend equipment lifespan, and enhance overall system efficiency. Whether you're in manufacturing, automotive, or any industry relying on clean compressed air, trust SMC Air Dryers to maintain peak performance and ensure your operations run smoothly.",

    'products':[
        {
            'img':require('../../images/SMC1.jpg'),
            'name':'Air Dryer',
            'details':'3 CFM TO 1000 CFM '
        },
       
    ],

}



,
{
    'name':'Electrical Actuator',
    'link':'/products/SMC_Products/Electrical_Actuator',
    'img':require('../../images/SMCM2.jpg'),

    'details':"Empower your automation with SMC Electric Actuators. Harnessing cutting-edge technology, our electric actuators deliver seamless motion control across diverse applications. From linear to rotary, gripping to rodless designs, experience precision, reliability, and efficiency in every movement. Elevate your industrial processes with SMC's trusted electric actuation solutions",

    'products':[
        {
            'img':require('../../images/SMC2.jpg'),
            'name':'Rod Type',
            'details':'Linear precision, powered electrically.(STEPPER AND SERVO BOTH )'
        },
        {
            'img':require('../../images/SMC3.jpg'),
            'name':'Rodless',
            'details':'Compact motion, rod-free design.(STEPPER AND SERVO BOTH )'
        },
        {
            'img':require('../../images/SMC4.jpg'),
            'name':'Gripper',
            'details':'Efficient handling, powered grip. (STEPPER AND SERVO BOTH )'
        },
        {
            'img':require('../../images/SMC5.jpg'),
            'name':'Rotatory',
            'details':'Controlled rotation, precise automation. (STEPPER AND SERVO BOTH )'
        },
       
    ],

}
,
{
    'name':'Electronic Flow Switch',
    'link':'/products/SMC_Products/Electronic_Flow_Switch',
    'img':require('../../images/SMCM3.jpg'),

    'details':"Optimize fluid control with SMC Electric Flow Switches. Designed for precision and reliability, our flow switches enable real-time monitoring and regulation of fluid flow in various systems. Experience seamless integration, accurate detection, and enhanced efficiency with SMC's trusted flow control solutions.",

    'products':[
        {
            'img':require('../../images/SMC6.jpg'),
            'name':'Electronic Flow Switch',
            'details':'0.2 TO 12000 LPM '
        },
       
    ],

}
,
{
    'name':'Air Cylinder',
    'link':'/products/SMC_Products/Air_Cylinder',
    'img':require('../../images/SMCM4.jpg'),

    'details':"Unleash efficiency with SMC Air Cylinders. Choose precision-driven Rod Type, space-saving Rodless, versatile Gripper, or controlled Rotary cylinders. Elevate your automation with SMC's diverse pneumatic solutions, delivering reliability and performance across every motion.",

    'products':[
        {
            'img':require('../../images/SMC7.jpg'),
            'name':'Rod Type',
            'details':'(Bore 4mm to 320 mm ) (Stroke 5mm to 3000mm)  '
        },
        {
            'img':require('../../images/SMC8.jpg'),
            'name':'Rodless',
            'details':'(Bore 4mm to 320 mm ) (Stroke 5mm to 3000mm)  '
        },
        {
            'img':require('../../images/SMC9.jpg'),
            'name':'Gripper',
            'details':'(Bore 4mm to 320 mm ) (Stroke 5mm to 3000mm)  '
        },
        {
            'img':require('../../images/SMC10.jpg'),
            'name':'Rotatory',
            'details':'(Bore 4mm to 320 mm ) (Stroke 5mm to 3000mm)  '
        },
        {
            'img':require('../../images/SMC11.jpg'),
            'name':'Rotatory',
            'details':'More Cynlinder Categories: Round Type, Square Profile, Compact Type, Rodless Type, Guide Type, Clamp Cylinder, Stopper Cynlinder, Shock Absorber, Floating Joint'
        },
       
    ],

}
,
{
    'name':'Valves',
    'link':'/products/SMC_Products/Valves',
    'img':require('../../images/SMCM5.png'),

    'details':"Experience seamless control with SMC Valves. From Air Operated to Electrical Operated, our valves offer precise command over fluid and air systems. Whether it's pneumatic or electric, trust SMC's valve solutions for efficient, reliable, and tailored performance in your industrial processes.",

    'products':[
        {
            'img':require('../../images/SMC12.jpg'),
            'name':'Air Operated',
            'details':''
        },
        {
            'img':require('../../images/SMC12.jpg'),
            'name':'Electrical Operated',
            'details':''
        },
       
    ],

}
,
{
    'name':'Vaccum Equipment',
    'link':'/products/SMC_Products/Vaccum_Equipment',
    'img':require('../../images/SMCM6.jpg'),

    'details':"Experience enhanced precision with SMC Vacuum Equipment. From powerful pumps to reliable suction cups, our vacuum solutions offer superior grip and control. Trust SMC's innovation for seamless automation, delivering consistent performance and increased productivity across applications.",

    'products':[
        {
            'img':require('../../images/SMC13.png'),
            'name':'Vaccum Equipment',
            'details':''
        },
       
    ],

}
,
{
    'name':'FRL',
    'link':'/products/SMC_Products/FRL',
    'img':require('../../images/SMC14.png'),

    'details':"Optimize performance with SMC's Essential Components",

    'products':[
        {
            'img':require('../../images/SMC14.png'),
            'name':'FRL',
            'details':'Enhance air quality and system efficiency with SMC FRL units. Combining filtration, regulation, and lubrication, these components ensure clean, controlled air supply for your operations. Trust SMC for reliable FRL solutions that elevate your pneumatic systems'
        },
        {
            'img':require('../../images/SMC14.png'),
            'name':'2 Port Valve',
            'details':'Experience precise fluid control with SMC 2 Port Valves. Whether air or liquid, these valves provide seamless on/off functionality, ensuring smooth operation in diverse applications. Count on SMC for efficient and dependable 2 Port Valve solutions.'
        },
        {
            'img':require('../../images/SMC14.png'),
            'name':'Booster',
            'details':"Amplify your pneumatic power with SMC Boosters. These devices increase air pressure for enhanced performance in applications requiring higher pressures. Rely on SMC's Boosters to optimize your systems for productivity and precision"
        },
       
    ],

}
,
{
    'name':'Electro Pneumatic Proportional Valve',
    'link':'/products/SMC_Products/Electro Pneumatic Proportional Valve',
    'img':require('../../images/SMC15.jpg'),

    'details':"Master fluid control effortlessly with SMC's Electro-Pneumatic Proportional Valves. Marrying electrical finesse and pneumatic might, these valves redefine precision in fluid flow regulation. From industrial automation to specialized processes, count on SMC for reliability in maintaining targeted pressure, flow, or position. Step into a new era of accuracy, productivity, and adaptability",

    'products':[
        {
            'img':require('../../images/SMC15.jpg'),
            'name':'Analog Type',
            'details':'(Port Size 1/8 to 3/8 ) (Pressure Range 0 to 50 bar)'
        },
        {
            'img':require('../../images/SMC15.jpg'),
            'name':'Digital Type',
            'details':'(Port Size 1/8 to 3/8 ) (Pressure Range 0 to 50 bar)'
        },
       
    ],

}
,


,]








 













