import React from 'react'
import './D6_SL_AF_CSS.css';
import {Grid,Container,Typography,Chip, Dialog, DialogContent, IconButton} from '@mui/material';
import {NavLink} from "react-router-dom";



import './D6_SL_AF_CSS.css'


const D6_SL_AF = () => {
  
    return (
<>

<Container style={{maxWidth:'100%',textAlign:'-webkit-center'}}>




        
                  </Container>
   <div className="D6_AboveFooter_Container">   

 <Container  style={{maxWidth:1200}} fixed className="D6_AF_Grid_Cont">
 <Grid container justify="center" className="D6_AF_Grid">

        <Grid item md={3} xs={12} className="D6_AF_Grid3">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-3">
                <h3 className="D6_AboveFooter_H3">Popular Products</h3>
                <ul className="D6_AboveFooter_List3">
                <li>
                        <NavLink className=""  to="/products">
                     Allen Bradely Products
                                          </NavLink>
                    </li>
                 
                   
                    <li>
                        <NavLink className=""  to="/products">
                       SMC Products                    </NavLink>
                    </li>
                    
                    <li>
                        {/* <NavLink className=""  to="/ContactUs">
                        STRUCTURE DESIGN
                                               </NavLink> */}
                    </li>
                    <li>
                        {/* <NavLink className=""  to="/ContactUs">
                        PLANNING & URBAN DESIGN                       </NavLink> */}
                    </li>
                </ul>
             
            </div>
        </Grid>

        <Grid item md={3} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">Head Office </h3>
                <div className="D6_AboveFooter_Item5D1">
                    <p className="D6_AboveFooter_HC1 D6_AboveFooter_HC11">
                   A-101 Assotech Cabana, 
              </p>
                 
                    <p className="D6_AboveFooter_HC1">
                    Indirapuram, Ghaziabad, Uttar Pradesh
                    </p>
                    <div className="D6_AboveFooter_Icon1">
                        <i className="fas fa-phone-alt "></i>
                        <a href="tel:+91-7428283299"className="D6_AboveFooter_Icon_H">
                        +91-7428283299
                        </a>  
                    </div>
                 
                    <div  style={{display:'flex'}}className="D6_AboveFooter_Icon1">
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:info@halbleiterengineering.com"  className="D6_AboveFooter_Icon_E">
                        info@halbleiterengineering.com
                        </a>  
                    </div>
                   

                
                </div>
            </div>
        </Grid>
        <Grid item md={3} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">Factory Address </h3>
                <div className="D6_AboveFooter_Item5D1">
                    <p className="D6_AboveFooter_HC1 D6_AboveFooter_HC11">
                    PLOT NO. 3 ACHHEJA, 
              </p>
                 
                    <p className="D6_AboveFooter_HC1">
                    GREATER NOIDA         
                              </p>
                              <p className="D6_AboveFooter_HC1">
                    U.P-203207          
                              </p>
                    
                   

                
                </div>
            </div>
        </Grid>
     
        <Grid item md={3} xs={12} className="D6_AF_Grid5">
            <div className="D6_AboveFooter_Item" id="D6_AboveFooter_Item-5">  
                <h3 className="D6_AboveFooter_H1">Reach Us</h3>
                <div className="D6_AboveFooter_Item5D1">
                 
     


                    <div className="Dec6_AboveFooter_SymbolsList1">
                        {/* <a href="'https://affiliate.fmstracking.online/"   target='_blank'   className="fas fa-user-lock circle-icon aicon centericons"></a> */}
                        <a   className="fab fa-linkedin-in circle-icon1 circle-icon-tw aicon"></a>
                        <a   className="fab fa-facebook circle-icon1   ">
                      
                      
                        </a>
                        <a   className="fab fa-twitter circle-icon1   ">
</a>
    
                     
                    </div> 
                </div>
            </div>
        </Grid>

    </Grid>   
</Container>  
</div>
</>
    )
}

export default D6_SL_AF
