import React,{useEffect} from 'react';
import './about_top.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

import CloseIcon from '@mui/icons-material/Close';

import { Avatar,Container,Grid,Typography,Chip, Dialog, DialogContent, IconButton} from '@mui/material';
import D6_DASAC_Form from '../common/footer/D6_SL_AF_Form';

function About_top() {
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return(
        <>
        <div data-aos="fade-down" className="Project_Purpose_Block">
            <div data-aos="fade-up" data-aos-delay="30" data-aos-easing="ease-in-sine" className="Project_Purpose_Block_Cont">
             {/*   <h3 data-aos="fade-left" data-aos-delay="50" className="Project_Purpose_Block_Cont_AH">
                    {Project_Purpose_Block_Cont_AH_Text}
                </h3>  */}
                <h4 data-aos="fade-right" data-aos-delay="130" className="Project_Purpose_Block_Cont_MH">
                
Our Vision                </h4>
                <p data-aos="flip-up"
                    data-aos-delay="250"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-out-cubic" className="Project_Purpose_Block_Cont_P">
At Halbleiter Engineering, we're looking ahead to a future where Industry Automation takes center stage in shaping tomorrow's businesses. Our vision is to be the trailblazers, setting new standards for innovation and operational excellence. </p>            </div>
        </div>
          <div data-aos="fade-down" className="Project_Purpose_Block1">
          <div data-aos="fade-up" data-aos-delay="30" data-aos-easing="ease-in-sine" className="Project_Purpose_Block_Cont">
           {/*   <h3 data-aos="fade-left" data-aos-delay="50" className="Project_Purpose_Block_Cont_AH">
                  {Project_Purpose_Block_Cont_AH_Text}
              </h3>  */}
              <h4 data-aos="fade-right" data-aos-delay="130" className="Project_Purpose_Block_Cont_MH">
              
              Our Mission
              </h4>
              <p data-aos="flip-up"
                  data-aos-delay="250"
                  data-aos-duration="2000"
                  data-aos-easing="ease-in-out-cubic" className="Project_Purpose_Block_Cont_P">
Our mission at Halbleiter Engineering is to provide industries with advanced and tailored Industry Automation solutions that propel growth, elevate efficiency, and inspire innovation. We are dedicated to curating a diverse range of high-quality products and services that cater to the unique needs of each client.      </p>
          </div>
      </div>
      <div data-aos="fade-down" className="Project_Purpose_Block2">
          <div data-aos="fade-up" data-aos-delay="30" data-aos-easing="ease-in-sine" className="Project_Purpose_Block_Cont">
           {/*   <h3 data-aos="fade-left" data-aos-delay="50" className="Project_Purpose_Block_Cont_AH">
                  {Project_Purpose_Block_Cont_AH_Text}
              </h3>  */}
              <h4 data-aos="fade-right" data-aos-delay="130" className="Project_Purpose_Block_Cont_MH">
              
              Our Capabilities
              </h4>
              <p data-aos="flip-up"
                  data-aos-delay="250"
                  data-aos-duration="2000"
                  data-aos-easing="ease-in-out-cubic" className="Project_Purpose_Block_Cont_P">
At HALBLEITER Engineering, we've assembled a complete crew dedicated to mechanical design. Our team is stacked with Mechanical Engineers and forward-thinking Technologists, complemented by the skills of adept Machine Builders. Backed by a collaborative unit of Machinists, Millwrights, assemblers, and innovative developers, we orchestrate every facet to guarantee a design that truly shines.     </p>
          </div>
      </div>

      <Container>
        <Grid container style={{alignContent:'flex-start',padding:'4vh 0px'}}>
            <Grid item style={{alignSelf:'center'}} sm={6} xs={12}>
                <img  style={{height:350}} src={require('../images/about.png')} />
            </Grid>
            <Grid container sm={6} xs={12}>
                <Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'2.5rem',fontWeight:'500',padding:'2vh 0px'}}>MORE ABOUT US</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >We are continuously reaching the new heights with a substantial growth every year. We have developed our modern manufacturing facility , by adopting innovative techniques to meet customer’s continuously changing requirements. Our team of talented employees emphasize to supply Quality Product in the field of Automation & Electrical Engineering to achieve total customer satisfaction through Quality management System .</Typography>

                </Grid>
                <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <Grid item style={{alignSelf:'center'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-exchange-alt"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'2vh 0px'}}>ADOPT CHANGES
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >We explore new concepts and adopt to changes to enhance our services to customers.


</Typography>

                </Grid>
</Grid>
                </Grid>
                <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <Grid item style={{alignSelf:'center'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="far fa-smile-beam"></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'2vh 0px'}}>CUSTOMER DELIGHT
</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >We ensure to provide best customer service possible, and we continually make efforts to exceed customer satisfaction.
</Typography>

                </Grid>
</Grid>
                </Grid>
                <Grid container style={{padding:'1vh 0px'}} sm={12} xs={12}>
                <Grid item style={{alignSelf:'center'}} sm={2} xs={2}>
<Avatar   style={{backgroundColor:'var(--blu3)',}}><i class="fas fa-box "></i></Avatar>
</Grid>
<Grid container textAlign={'left'} sm={10} xs={10}>
<Grid item sm={12} xs={12}>
<Typography style={{color:'var(--blu3)',fontSize:'1rem',fontWeight:'700',padding:'2vh 0px'}}>INNOVATION</Typography>
                </Grid>
                <Grid item sm={12} xs={12}>
                <Typography style={{textAlign:'justify',color:'black',fontWeight:'500'}}  paragraph >We endeavour to apply innovative thinking to everything we do.
</Typography>

                </Grid>
</Grid>
                </Grid>
            </Grid>
        </Grid>
      </Container>
      <div className="bg_belowTable">

<Grid container style={{paddingTop:'6%'}}>
<Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Post your query here..            
          </Typography>
          <D6_DASAC_Form  />
        </DialogContent>
      </Dialog>
    <Grid item sm={12} xs={12}>
<Typography className="head1">
Gain Success With Us!
</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className="head2">
Done the right thing
</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className="head3">
At the Right Time
</Typography>
    </Grid>
    <Grid item sm={12} onClick={()=>setOpen(true)} style={{textAlign:'center',paddingTop:25}} xs={12}>
<Chip label='Become a Client'  className="chip_below"/>
    </Grid>
</Grid>

        </div>
      </>
    );
}

export default About_top;