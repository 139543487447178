import { Grid ,Container} from '@mui/material'
import React from 'react'
import './D6_SL_Footer_CSS.css'
import { NavLink } from 'react-router-dom'

const D6_SL_Footer = () => {
    return (
        <div className="Dec6_Footer_Items_Block">
        <Container className="Dec6_Footer_Items">
            
            <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink1">
                {/* <NavLink className="Dec6_Footer_Link" exact to="/">
                        HOME
                </NavLink> */}
            </div>
            {/* <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink2">
                <NavLink className="Dec6_Footer_Link" exact to="/PrivacyPolicy">
                        PRIVACY POLICY
                </NavLink>
            </div>
            <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink3">
                <NavLink className="Dec6_Footer_Link" exact to="/Terms&Conditions">
                        TERMS    &    CONDITIONS
                </NavLink>
            </div> */}
            <div className="Dec6_Footer_Items_P_Div">  
                <p className="Dec6_Footer_Items_P">
                    &#169;2023 Halbleiter Engineering Private Limited
                </p>
            </div>
        </Container>
    </div>
    )
}

export default D6_SL_Footer
