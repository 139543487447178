import React, { useEffect } from 'react'
import './top_home.css'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import { Container, Grid, Typography } from '@mui/material';

export default function Top_home() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 
  return (
    <>
    <div data-aos="fade-in" className='bg_topS'>
    <div class="max-width">
          <div class="crousel-content">
            <h2 data-aos="fade-up"
     data-aos-duration="3000"   data-aos-delay="300" >HALBLEITER ENGINEERING</h2>
            <p data-aos="fade-up"
     data-aos-duration="3000"   data-aos-delay="320" >
Your Gateway to Advanced Industry Automation Solutions             {/* <p style={{marginTop:'15px'}}><span>We offer to our customers the most comprehensive range of Automation Solutions for Distribution & Electrification along with world class service team</span> </p> */}
            </p>
            <p data-aos="fade-up"
     data-aos-duration="3000"   data-aos-delay="330"  class="sub-label">


      
Discover the Future of Industry Automation with Halbleiter Engineering<br/> 

At Halbleiter Engineering, we're not just a distributor; we're your strategic partner in navigating the ever-evolving landscape of Industry Automation. Our commitment to excellence and innovation sets us apart as a premier provider of top-tier products and services. 
     </p>
       
          </div>
        </div>


    </div>
  
    </>
  )
}
