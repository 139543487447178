import {  Container, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import { Automations } from './automation_data';
import Card_C from '../common/card/card';
import { Helmet } from 'react-helmet';
export default function Industry() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 
  
// console.log(product,category)
const data_indus=[
    {
        name:'Smart Energy Management Systems',
        img:require('../images/IND1.png'),
        details:'Reduce energy consumption, cost & carbon footprints with improved data visibility, insights, automation, data-driven maintenance & smart problem isolation.'
    },
    {
        name:'Smart Water Management System',
        img:require('../images/IND2.jpg'),
        details:'Cloud-based monitoring and analysis of water infrastructures that include treatment plants, pumping stations, tanks, pipelines, etc. Connect sensors and assets.'
    },
    {
        name:'Remote Monitoring / Downtime Monitoring',
        img:require('../images/IND3.png'),
        details:'We provide data logging solutions with innovative sensing techniques. A data logger is a self-contained unit that does not require a host to operate.'
    },
    {
        name:'Predictive Maintenance',
        img:require('../images/IND4.png'),
        details:'The data collected from industries can be used to establish trends, predict failure, and calculate remaining life of an asset.'
    },
    {
        name:'Production Management System',
        img:require('../images/IND5.jpg'),
        details:'Pharma, Food industries, Beverages industries, heavy asset processing industries, such as power generation.'
    },

]

    return (
        <div style={{paddingBottom:'5vh'}}>
     <div>
        <Helmet>
        <title>Domain Expertise</title>
       

       <meta
         name="description"
         content={'Our comprehensive cloud-based IoT software platform that drives the digital transformation of infrastructure operations. Smart Cloud helps in optimizing the operations of utilities and machines.'}
       />

   <meta name="keywords" content={'Great Planners and Organizers,Incredible Team Working Abilities,Dedicated Expert Teams & Crew'} />

       {/* <!-- Google / Search Engine Tags --> */}
      
  
       <meta itemprop="name" content={'Contact Us'}
        />
       

   <meta
   itemprop="description"
   content={'Our comprehensive cloud-based IoT software platform that drives the digital transformation of infrastructure operations. Smart Cloud helps in optimizing the operations of utilities and machines.'}
 />
      
       
   

       {/* <!-- Facebook Meta Tags --> */}
     
     
       <meta property="og:url" content="https://halbleiterengineering.com/contactus" />
 
       <meta property="og:type" content="website" />
      
       <meta property="og:title" content={'Contact Us'} />
 
  
   <meta
         property="og:description"
         content={'Our comprehensive cloud-based IoT software platform that drives the digital transformation of infrastructure operations. Smart Cloud helps in optimizing the operations of utilities and machines.'}
       />
       
     
     

     
      
     </Helmet>
     <div style={{background:'var(--blu3)',height:'400px'}}>
 <Typography className='product_name'>
 {'INDUSTRY 4.0'}
 </Typography>
 <Typography style={{padding:'4vh 0px',color:'white',fontSize:18}} className='page'>
 <a href='/'  style={{textDecoration:'none',color:'white'}}>Home</a><span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span><a href='/'  style={{textDecoration:'none',color:'white'}}>Domain Expertise</a><span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span>{'Industry 4.0'}
 </Typography>
     </div>
 
     <Container style={{paddingTop:'6vh',textAlign:'-webkit-center'}}>
     <Grid container >
             <Grid container sm={6} xs={12}>
                 <Grid item sm={12} xs={12}>
                     <Typography className='product_title'>
                     INDUSTRY 4.0 (DIGITAL TRANSFORMATION)
                     </Typography>
                 </Grid>
                 <Grid item sm={12} xs={12}>
                     <Typography className='product_details'>
                     Our comprehensive cloud-based IoT software platform that drives the digital transformation of infrastructure operations. Smart Cloud helps in optimizing the operations of utilities and machines. Several enterprises have leveraged Smart Cloud’s capabilities to drive smart visibility, automate their operations, incorporate analytics & perform data-driven decision making.
                     </Typography>
                 </Grid>
                 {/* <Grid item sm={12} xs={12}>
                     <Typography className='product_details'>
                     {val.details}
                     </Typography>
                 </Grid> */}
             </Grid>
             <Grid item style={{padding:10,alignSelf:'center'}} sm={6} xs={12}>
                 <img data-aos="zoom-in-up" style={{maxWidth:'80%'}} src={require('../images/industrial.png')} />
</Grid>




         </Grid>
      
         <Grid container >

        
          {data_indus.map((val,idx)=>
   
   <Grid key={idx} style={{padding:15}} item sm={4} xs={12}>

   <Card_C idx={idx}  val={val} />
   </Grid>
    )  }
     
   
   </Grid>

           
 
         
        
      
      
       

       
     </Container>
     </div>
 
        </div>
        
      )
}
