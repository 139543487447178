import React from "react";
import Below_crousel from "./home_belowCrousel/below_crousel";
import Top_home from "./top_home/top_home";
import Bottom_sectionC from "./bottom_section/bottom_section";
import Products from "./products/products";

import {Helmet} from 'react-helmet'
export default function Homepage(){
    return(
        <>
          <Helmet>
        {/* <!-- HTML Meta Tags --> */}
    
    <title>Home</title>
       

        <meta
          name="description"
          content={'A Complete Engineering & Automation Solutions Company'}
        />

    <meta name="keywords" content={'Great Planners and Organizers,Incredible Team Working Abilities,Dedicated Expert Teams & Crew'} />

        {/* <!-- Google / Search Engine Tags --> */}
       
   
        <meta itemprop="name" content={'Home'}
         />
        
 
    <meta
    itemprop="description"
    content={'A Complete Engineering & Automation Solutions Company'}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content="https://halbleiterengineering.com/" />
  
        <meta property="og:type" content="website" />
       
        <meta property="og:title" content={'Home'} />
  
   
    <meta
          property="og:description"
          content={'A Complete Engineering & Automation Solutions Company'}
        />
        
      
      

      
       
      </Helmet>
        <Top_home />
        <Below_crousel />
        <Bottom_sectionC />
        <Products />
        </>
    )
}