import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Paper, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import './main_products.css'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Allen, SMC } from '../../homepage/products/products_data';
import Card_C from '../../common/card/card';

export default function Main_products() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 
    return (
   <Container style={{maxWidth:'100%'}}>
    <Grid style={{maxWidth:'100%'}} container>
        <Grid item style={{maxWidth:'100%'}} sm={12} xs={12}>
            <Paper data-aos="zoom-in-up" className='main_ppr' elevation={14}>
                <Typography style={{color:'white',textAlign:'left',paddingLeft:'2vw',fontSize:20}}>
                OUR PRODUCTS

                </Typography>
            </Paper>
        </Grid>
        <Grid item style={{maxWidth:'100%',padding:'3vh 0px',fontSize:20}} sm={12} xs={12}>
        <Accordion 
        defaultExpanded
        >
        <AccordionSummary
        
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{textTransform:'uppercase',color:'var(--blu3)'}}>Allen Bradley Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
          {Allen.map((val,idx)=>
          <Grid   key={idx}data-aos="zoom-in-up" style={{padding:'2vh 0px'}} item sm={4} xs={12}> 
    <Card_C show={true} idx={idx} val={val} />
 </Grid> )  }
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
      
      defaultExpanded
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{textTransform:'uppercase',color:'var(--blu3)'}}>SMC Products</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sm={12} xs={12}>
          {SMC.map((val,idx)=>
          <Grid   key={idx} data-aos="zoom-in-up" style={{padding:'2vh 0px'}} item sm={4} xs={12}> 
    <Card_C show={true} idx={idx} val={val} />
 </Grid> )  }
          </Grid>
        </AccordionDetails>
      </Accordion>
   
   
        </Grid>
    </Grid>
   </Container>
  )
}
