import './App.css';
import {Routes,Route,BrowserRouter} from "react-router-dom";
import ScrollNPT from './common/STTP/scroll_Top';
import Header from './common/header/header';
import STTopARROW from './common/Scroll_To_Top_Arrow/PP_STTop';
import D6_SL_Footer from './common/footer/D6_SL_Footer';
// import Header from './common/header/header';
import D6_SL_AF from './common/footer/D6_SL_AF';
import Homepage from './homepage/homepage';
import About from './about/about';
import Services from './services/services';
import Sub_product from './services/sub_product/sub_product';
import ContactUS from './contactus/contactus';
import Automation_D from './domain/automation';
import Industry from './domain/industry';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <ScrollNPT />
    <Header />
    <Routes>
    {/* <Route  path="/home" element={<Homepage />}  />  */}
    <Route  path="/" element={<Homepage/>}  /> 

     <Route  path="/about" element={<About />}  /> 
     <Route  path="/products" element={<Services />}  /> 
     <Route  path="/products/:category/:product" element={<Sub_product />}  /> 
     <Route  path="/contactus" element={<ContactUS />}  /> 
     <Route  path="/domain-expertise/industial-automation" element={<Automation_D />}  /> 
     <Route  path="/domain-expertise/industry-4.0" element={<Industry />}  /> 


{/*
    // <Route  path="/projects/All" element={<SubCategory />}  /> 

    // <Route  path="/projects/P" element={<Particular_project />}  /> 

    // <Route  path="/studio" element={<Studio />}  /> 

    <Route  path="/contactus" element={<ContactUS />}  /> 
    <Route  path="/careers" element={<Careers />}  /> 
    <Route  path="/expertise" element={<Expertise />}  />  */}



    </Routes>
    <STTopARROW />
    <D6_SL_AF />
    <D6_SL_Footer />
    </BrowserRouter>
    </div>
  );
}

export default App;
