import { Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import './sub_product.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Allen, SMC } from '../../homepage/products/products_data';
import Card_C from '../../common/card/card';
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import { Helmet } from 'react-helmet';
export default function Sub_product() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 
    let { category,product } = useParams();
    category=category.replace('_',' ').replace('_',' ')
    product=product.replace('_',' ').replace('_',' ')
// console.log(product,category)

   
if(category.toLowerCase()==='allen bradley products'){
    return (


        
        <div style={{paddingBottom:'5vh'}}>
    
    <Helmet>
        {/* <!-- HTML Meta Tags --> */}
    
    <title>Products</title>
       

        <meta
          name="description"
          content={'Experience a comprehensive array of Industry Automation essentials under one roof. From state-of-the-art Pneumatic Products to precision-engineered Variable Frequency Drives (VFDs), from versatile Programmable Logic Controllers (PLCs) to intuitive Human Machine Interfaces (HMIs), and from advanced Actuators to reliable Air Cylinders and Valves – Halbleiter offers a curated selection that empowers your automation endeavors.'}
        />

    <meta name="keywords" content={'Great Planners and Organizers,Incredible Team Working Abilities,Dedicated Expert Teams & Crew'} />

        {/* <!-- Google / Search Engine Tags --> */}
       
   
        <meta itemprop="name" content={'Products'}
         />
        
 
    <meta
    itemprop="description"
    content={'Experience a comprehensive array of Industry Automation essentials under one roof. From state-of-the-art Pneumatic Products to precision-engineered Variable Frequency Drives (VFDs), from versatile Programmable Logic Controllers (PLCs) to intuitive Human Machine Interfaces (HMIs), and from advanced Actuators to reliable Air Cylinders and Valves – Halbleiter offers a curated selection that empowers your automation endeavors.'}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content="https://halbleiterengineering.com/products" />
  
        <meta property="og:type" content="website" />
       
        <meta property="og:title" content={'Products'} />
  
   
    <meta
          property="og:description"
          content={'Experience a comprehensive array of Industry Automation essentials under one roof. From state-of-the-art Pneumatic Products to precision-engineered Variable Frequency Drives (VFDs), from versatile Programmable Logic Controllers (PLCs) to intuitive Human Machine Interfaces (HMIs), and from advanced Actuators to reliable Air Cylinders and Valves – Halbleiter offers a curated selection that empowers your automation endeavors.'}
        />
        
      
      

      
       
      </Helmet>
    {Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase())).map((val,idx)=>
     <div key={idx}>
        
     <div style={{background:'var(--blu3)',height:'420px'}}>
 <Typography className='product_name'>
 {product}
 </Typography>
 <Typography style={{padding:'4vh 0px',color:'white',fontSize:18}} className='page'>
 <a href='/'  style={{textDecoration:'none',color:'white'}}>Home</a><span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span><a href='/'  style={{textDecoration:'none',color:'white'}}>Products</a><span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span>{category}<span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span>{product}
 </Typography>
     </div>
 
     <Container style={{paddingTop:'6vh',maxWidth:'100%',textAlign:'-webkit-center'}}>
         <Grid container style={{maxWidth:1200}}>
             <Grid container sm={6} xs={12}>
                 <Grid item sm={12} xs={12}>
                     <Typography className='product_title'>
                         {val.name}
                     </Typography>
                 </Grid>
                 <Grid item sm={12} xs={12}>
                     <Typography className='product_details'>
                     {val.details}
                     </Typography>
                 </Grid>
                 {/* <Grid item sm={12} xs={12}>
                     <Typography className='product_details'>
                     {val.details}
                     </Typography>
                 </Grid> */}
             </Grid>
             <Grid item style={{padding:10,alignSelf:'center'}} sm={6} xs={12}>
                 <img data-aos="zoom-in-up" style={{maxWidth:'80%'}} src={val.img} />
</Grid>
<Grid style={{marginBottom:'3vh'}} item sm={12} xs={12}>
<Typography style={{textAlign:'center'}} data-aos="fade-up"
      className='head_about_new'>
Products Of {product}
</Typography>


</Grid>



         </Grid>
   <Grid container style={{maxWidth:'100%'}}>
{Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].name2 ?  <Grid style={{marginBottom:'3vh'}} item sm={12} xs={12}>
<Typography style={{textAlign:'start',paddingLeft:'3vw'}} data-aos="fade-up"
      className='head_about_new'>
{Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].name2}
</Typography>


</Grid>:null}



{Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products&&Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products[0].head ?  <Grid style={{marginBottom:'3vh'}} item sm={12} xs={12}>
<Typography style={{textAlign:'start',paddingLeft:'3vw'}} data-aos="fade-up"
      className='head_about_new'>
{Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products[0].head}
</Typography>


</Grid>:null}

      {Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products.map((val,idx)=>
    <Grid key={idx} style={{padding:15}} item sm={3} xs={12}>
    <Card_C idx={idx} val={val} />
    </Grid>
    )  }



    {Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0]&&Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products2?<>
    {Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products2&&Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products2[0].head ?  <Grid style={{marginBottom:'3vh'}} item sm={12} xs={12}>
<Typography style={{textAlign:'start',paddingLeft:'3vw'}} data-aos="fade-up"
      className='head_about_new'>
{Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products2[0].head}
</Typography>


</Grid>:null}

      {Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products2.map((val,idx)=>
    <Grid key={idx} style={{padding:15}} item sm={3} xs={12}>
    <Card_C idx={idx} val={val} />
    </Grid>
    )  }
    </>:null}
    {Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0]&&Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products3?<>
    {Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products3&&Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products3[0].head ?  <Grid style={{marginBottom:'3vh'}} item sm={12} xs={12}>
<Typography style={{textAlign:'start',paddingLeft:'3vw'}} data-aos="fade-up"
      className='head_about_new'>
{Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products3[0].head}
</Typography>


</Grid>:null}

      {Allen.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products3.map((val,idx)=>
    <Grid key={idx} style={{padding:15}} item sm={3} xs={12}>
    <Card_C idx={idx} val={val} />
    </Grid>
    )  }
    </>:null}
      </Grid>

      
           
 
         
        
      
      
       

       
     </Container>
     </div>
    )
   }
        </div>
        
      )
}
else if(category.toLocaleLowerCase()==='smc products'){
    return (
        <div style={{paddingBottom:'5vh'}}>
    {SMC.filter(val=>val.name.toLocaleLowerCase()===product.toLocaleLowerCase()).map((val,idx)=>
     <div>
        
     <div style={{background:'var(--blu3)',height:'420px'}}>
 <Typography className='product_name'>
 {product}
 </Typography>
 <Typography style={{padding:'4vh 0px',color:'white',fontSize:18}} className='page'>
 <a href='/'  style={{textDecoration:'none',color:'white'}}>Home</a><span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span><a href='/'  style={{textDecoration:'none',color:'white'}}>Products</a><span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span>{category}<span><i style={{padding:'0px 5px',color:'white'}} class="fas fa-angle-right"></i></span>{product}
 </Typography>
     </div>
 
     <Container style={{paddingTop:'6vh',maxWidth:'100%',textAlign:'-webkit-center'}}>
         <Grid container style={{maxWidth:'1200px'}}>
             <Grid container sm={6} xs={12}>
                 <Grid item sm={12} xs={12}>
                     <Typography className='product_title'>
                     {val.name}
                     </Typography>
                 </Grid>
                 <Grid item sm={12} xs={12}>
                     <Typography className='product_details'>
                     {val.details}
                     </Typography>
                 </Grid>
                 {/* <Grid item sm={12} xs={12}>
                     <Typography className='product_details'>
                     {val.details}
                     </Typography>
                 </Grid> */}
             </Grid>
             <Grid item style={{padding:10,alignSelf:'center'}} sm={6} xs={12}>
                 <img data-aos="zoom-in-up" style={{maxWidth:'80%'}} src={val.img} />
</Grid>
<Grid style={{marginBottom:'3vh'}} item sm={12} xs={12}>
<Typography style={{textAlign:'center'}} data-aos="fade-up"
      className='head_about_new'>
Products Of {product}
</Typography>


</Grid>



         </Grid>
         <Grid container style={{maxWidth:'100%'}}>
        

      {SMC.filter(val=>val.name.toLowerCase().includes(product.toLowerCase()))[0].products.map((val,idx)=>
   
   <Grid key={idx} style={{padding:15}} item sm={3} xs={12}>

   <Card_C idx={idx}  val={val} />
   </Grid>
    )  }
   
   </Grid>

           
 
         
        
      
      
       

       
     </Container>
     </div>
    )
   }
        </div>
        
      )
}
 else{
    window.location.href='/products'
 }
}
