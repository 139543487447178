import React, { useEffect } from 'react'
// import './top_studio.css'
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import { Container, Grid, Typography } from '@mui/material';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// import '../../studio/top_studio/top_studio.css'
import './products.css'
import { Allen, SMC } from './products_data';
import Card_C from '../../common/card/card';

export default function Products() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 
    
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
   
  
  return (
    <>
   
    <Container>
        <Grid container>
<Grid item sm={12} xs={12}>
<Typography style={{textAlign:'center'}} data-aos="fade-up"
      className='head_about_new'>
   Our Products
</Typography>


</Grid>

</Grid>
{/* <Grid container  */}

{/* <Grid data-aos="fade-up"
     data-aos-duration="3000"   data-aos-delay="100" container sm={12} xs={12}> */}
     <Container style={{margin:'3rem 0px'}}>
     <Grid item sm={12} xs={12}>
<Typography style={{textAlign:'start',paddingTop:'1vh',color:'var(--blu4)'}} data-aos="fade-up"
      className='head_about'>
Allen Bradley Products</Typography>


</Grid>
<Carousel
  swipeable={true}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={ true}
  autoPlaySpeed={2200}
  keyBoardControl={true}
//   slidesToSlide={3}
slidesToSlide={4}
  


  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>

      {Allen.map((val,idx)=>

  <Card_C show={true} idx={idx} val={val} />
   )  }
   
      
           
 
         
        
      
      
       

       
</Carousel>
</Container>
<Container style={{margin:'3rem 0px'}}>
     <Grid item sm={12} xs={12}>
<Typography style={{textAlign:'start',paddingTop:'1vh',color:'var(--blu4)'}} data-aos="fade-up"
      className='head_about'>
SMC Products</Typography>


</Grid>
<Carousel
  swipeable={true}
  draggable={false}
  showDots={true}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlay={ true}
  autoPlaySpeed={2200}
  keyBoardControl={true}
//   slidesToSlide={3}
// slidesToSlide={1}

  


  containerClass="carousel-container"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  dotListClass="custom-dot-list-style"
  itemClass="carousel-item-padding-40-px"
>

      {SMC.map((val,idx)=>
     <Card_C show={true} idx={idx} val={val} />
     )  }
   
      
           
 
         
        
      
      
       

       
</Carousel>
</Container>
{/* </Grid> */}
{/* </Grid> */}
    </Container>
    </>
  )
}
