export const Automations=[
   
    {
        img:require('../images/AT7.jpg'),
        name:'MACHINE TOOL'
    }, {
        img:require('../images/AT5.jpg'),
        name:'LIFE SCIENCE & PHARMACEUTICAL'
    },
 
    
    {
        img:require('../images/AT4.jpg'),
        name:'BEVERAGE'
    },
   
   
    {
        img:require('../images/AT6.jpg'),
        name:'PRINTING'
    },
    {
        img:require('../images/AT2.jpg'),
        name:'FOOD'
    },
    {
        img:require('../images/AT10.jpg'),
        name:'PULP & PAPER'
    },
    
    {
        img:require('../images/AT1.jpg'),
        name:'AUTOMOBILE '
    },
    {
        img:require('../images/AT9.jpg'),
        name:'BEER'
    },
]