import React from 'react'
import Top_services from './top_services/top_services'
import Main_products from './main_products/main_products'
import { Helmet } from 'react-helmet'

export default function Services() {
  return (
<>
<Helmet>
        {/* <!-- HTML Meta Tags --> */}
    
    <title>Products</title>
       

        <meta
          name="description"
          content={'Experience a comprehensive array of Industry Automation essentials under one roof. From state-of-the-art Pneumatic Products to precision-engineered Variable Frequency Drives (VFDs), from versatile Programmable Logic Controllers (PLCs) to intuitive Human Machine Interfaces (HMIs), and from advanced Actuators to reliable Air Cylinders and Valves – Halbleiter offers a curated selection that empowers your automation endeavors.'}
        />

    <meta name="keywords" content={'Great Planners and Organizers,Incredible Team Working Abilities,Dedicated Expert Teams & Crew'} />

        {/* <!-- Google / Search Engine Tags --> */}
       
   
        <meta itemprop="name" content={'Products'}
         />
        
 
    <meta
    itemprop="description"
    content={'Experience a comprehensive array of Industry Automation essentials under one roof. From state-of-the-art Pneumatic Products to precision-engineered Variable Frequency Drives (VFDs), from versatile Programmable Logic Controllers (PLCs) to intuitive Human Machine Interfaces (HMIs), and from advanced Actuators to reliable Air Cylinders and Valves – Halbleiter offers a curated selection that empowers your automation endeavors.'}
  />
       
        
    

        {/* <!-- Facebook Meta Tags --> */}
      
      
        <meta property="og:url" content="https://halbleiterengineering.com/products" />
  
        <meta property="og:type" content="website" />
       
        <meta property="og:title" content={'Products'} />
  
   
    <meta
          property="og:description"
          content={'Experience a comprehensive array of Industry Automation essentials under one roof. From state-of-the-art Pneumatic Products to precision-engineered Variable Frequency Drives (VFDs), from versatile Programmable Logic Controllers (PLCs) to intuitive Human Machine Interfaces (HMIs), and from advanced Actuators to reliable Air Cylinders and Valves – Halbleiter offers a curated selection that empowers your automation endeavors.'}
        />
        
      
      

      
       
      </Helmet>
    <Top_services />
    <Main_products />
    </>
  )
}
