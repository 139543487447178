import React from 'react';
import {Link, NavLink} from "react-router-dom";

import Grid from '@mui/material/Grid';
import './header.css'
import { Allen, SMC } from '../../homepage/products/products_data';

function Navbar() {
       
   
        return(
            <>
            <div className='D6_SL_Navbar_Items1'>
<Grid justifyContent={'center'} alignContent={'center'} container className='gird_nav'>
<Grid item>
<NavLink  to="/"   className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}     >
                              HOME
                            </NavLink>
</Grid>
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="about"   >
                              ABOUT US
                            </NavLink>
</Grid>


<Grid item className='dropdown'>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2N" : "D6_SL_Navbar_Link1N"}  to="domain-expertise/industial-automation"   >
DOMAIN EXPERTISE
<div className="dropdown-content">
  

<div className='dropdown1'>
    <Link to={'/domain-expertise/industial-automation'}>{'INDUSTRIAL AUTOMATION'}</Link>
 
    </div>
    <div className='dropdown2'>
    <Link to={'/domain-expertise/industry-4.0'}>{'INDUSTRY 4.0'}</Link>

    </div>
  
  </div>
                            </NavLink>
</Grid>

<Grid item className='dropdown'>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2N" : "D6_SL_Navbar_Link1N"}  to="products"   >
PRODUCTS
<div className="dropdown-content">
  

<div className='dropdown1'>
    <a  >Allen Bradley Products</a>
    <div className="dropdown-content1">
   {Allen.map((val,idx)=>
  <Link key={idx}  to={val.link.replace(' ','_')} >{val.name}</Link> ) }
    

    </div>
    </div>
    
    <div className='dropdown2'>
    <a  >SMC Products</a>
    <div className="dropdown-content2">
    {SMC.map((val,idx)=>
  <Link key={idx}  to={val.link.replace(' ','_')} >{val.name}</Link> ) }
  </div>
    </div>
  
  </div>
                            </NavLink>
</Grid>
{/* <Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="careers"   >
CAREERS
                            </NavLink>

</Grid> */}
<Grid item>
<NavLink className={({ isActive }) => isActive ? "D6_SL_Navbar_Link2" : "D6_SL_Navbar_Link1"}  to="contactus"   >
CONTACT US
                            </NavLink>

</Grid>

{/* <Grid item>
<div className= "D6_SL_Navbar_Link1"  onClick={()=>window.open("https://affiliate.fmstracking.online/",'_blank')}   >
                            PRIVACY PILICY
                            </div>
</Grid> */}
{/* <Grid item>
<div className= "D6_SL_Navbar_Link1"  onClick={()=>window.open("https://affiliate.fmstracking.online/",'_blank')}   >
                            TERMS & CONDITIONS
                            </div>
</Grid> */}
    </Grid>                  
            </div>








            </>
        );
    }
    
    export default Navbar;