import { Button, Card, CardActions, CardContent, CardMedia, Dialog, DialogContent, IconButton, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import D6_DASAC_Form from '../footer/D6_SL_AF_Form';

export default function Card_C(props) {
   const {val,idx}=props
   const [open, setOpen] = React.useState(false);
   const [type, SetType] = React.useState('');

   const handleClose = () => {
       setOpen(false);
   };
   const Open=(type)=>{
    SetType(type.name)
    setOpen(true)

   }
  return (
    
    <div className='card_common' style={{height:'100%'}}>
    <Card   style={{height:'100%',display:'grid'}} key={idx} sx={{ maxWidth: 345 }}>
        <Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Post your query here..            
          </Typography>
          <D6_DASAC_Form  type={type} />
        </DialogContent>
      </Dialog>
    <CardMedia
      sx={{height:160 }}
      image={val.img}
      style={{backgroundSize:props.w?'cover':'contain',backgroundPosition:props.w?'top':'center'}}
      title="green iguana"
    />
    <CardContent style={{alignSelf:'start'}}>
      <Typography style={{textAlign:'start',color:'var(--blu3)'}} gutterBottom variant="h5" component="div">
        {val.name}
      </Typography>
      <Typography style={{textAlign:'justify',fontWeight:'500'}}  variant="body2" color="text.secondary">
      {val.details}

      </Typography>
    </CardContent>
    <CardActions style={{alignSelf:'end'}}>
      <Button size="small" onClick={()=>Open(val)} variant='contained' style={{backgroundColor:'var(--blu3)',color:'white'}}>Request a query</Button>
{props.show?      <Button onClick={()=>window.location.href=`${val.link}`} size="small" variant='outlined'>Read More</Button>
:null}    </CardActions>
  </Card>  
  </div>
  )
}
