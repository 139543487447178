import {Container,Grid,Typography,Chip, Dialog, DialogContent, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from "react"
import Image1 from'../../images/one.svg'
import Image2 from'../../images/quality.svg'
import Image3 from'../../images/success.svg'

import './below_crousel.css'
import D6_DASAC_Form from '../../common/footer/D6_SL_AF_Form';




export default function Below_crousel(){
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    return(
        <>
        <Container>
<Grid container  >
<Grid style={{textAlign:'center'}} item sm={4} xs={12} >
<Grid sm={12}  xs={12} >
<img   src={Image1}  className='below_icon'/>
</Grid>
<Grid sm={12} xs={12} >
<Typography  className='below_icon_text'>
Great Planners and Organizers


</Typography>
</Grid>

</Grid>

<Grid style={{textAlign:'center'}} item sm={4} xs={12} >
<Grid sm={12}  xs={12} >
<img   src={Image3} className='below_icon'/>
</Grid>
<Grid sm={12} xs={12} >
<Typography className='below_icon_text'>
Incredible Team Working Abilities
</Typography>
</Grid>

</Grid>
<Grid   style={{textAlign:'center'}} item sm={4} xs={12} >
<Grid sm={12}  xs={12} >
<img   src={Image2} className='below_icon'/>
</Grid>
<Grid sm={12} xs={12} >
<Typography className='below_icon_text'>
Dedicated Expert Teams & Crew
</Typography>
</Grid>

</Grid>
</Grid>
</Container>




<div className="bg_belowTable">

<Grid container style={{paddingTop:'6%'}}>
<Dialog
        open={open}
        // TransitionComponent={Transition}
        disableScrollLock={true}

        onClose={handleClose}
        className='Dialog_CButton1'
        PaperProps={{ elevation: 0 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            
            className='icon-close-btn2'>
            <CloseIcon />
          </IconButton>
          <Typography
            component="h5"
            variant="h5"
            className='modal-title'>
Post your query here..            
          </Typography>
          <D6_DASAC_Form  />
        </DialogContent>
      </Dialog>
    <Grid item sm={12} xs={12}>
<Typography className="head1">
Gain Success With Us!
</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className="head2">
Done the right thing
</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
<Typography className="head3">
At the Right Time
</Typography>
    </Grid>
    <Grid item sm={12} onClick={()=>setOpen(true)} style={{textAlign:'center',paddingTop:25}} xs={12}>
<Chip label='Become a Client'  className="chip_below"/>
    </Grid>
</Grid>

        </div>

</>
    )

}




